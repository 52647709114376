h1 {
  margin: 0 0 12px;
}

h3 {
  margin: 0;
}

li {
  text-align: left;
}

ul {
  list-style: none;
  padding: 0;
}

nav {
  margin: 20px 0 0;
}

a:link,
a:visited,
a:hover,
a:active {
  color: black;
  cursor: pointer;
}
