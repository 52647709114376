.profilePic {
  width: 85%;
  margin: auto;
}

.desc {
  line-height: 1.5em;
  width: 85%;
  margin: auto;
}
